<style lang="scss" scoped>
.line {
	margin: 0.3rem 0.3rem;
	height: 1px;
	background: $line_color;
}
::v-deep .van-cell {
	padding: 0.2rem 0;
	border-bottom: 0.02rem solid #eeeff0;
	&:last-child {
		border-bottom: 0;
	}
}
::v-deep .cell_title {
	color: $font_color_sec;
	font-size: 0.24rem;
}
::v-deep .cell_value {
	color: $font_color_val;
	font-size: 0.24rem;
}
::v-deep .van-field__body input {
	color: $font_color_val;
}
::v-deep .no_link .van-icon-arrow {
	color: #fff;
}
.btns {
	margin: 0.4rem 0 0;
}
.btns_user_pay_type {
	margin: 0.3rem;
	border-radius: 0.12rem;

	.add_assured {
		margin-bottom: 0.3rem;
	}
}

.user_item {
	display: flex;
	align-items: center;
	margin-left: 4px;
	.delete {
		font-size: 20px;
	}
}
.user_icon_delete {
	float: right;
	color: $danger;
}
.add_assured {
	margin-bottom: 0.2rem;
}

.user_info_row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	.user_title {
		font-size: 0.28rem;
		display: flex;
		align-items: center;
		line-height: 0.4rem;
		.index {
			color: $color_active_3;
			font-size: 0.4rem;
			font-weight: bold;
			width: 0.6rem;
		}
		.card_num,
		.name {
			margin-right: 8px;
		}
	}
}

.tab_box {
	color: $font_color_sec;
	text-align: center;
	.tab_line_box {
		font-size: 0;
		margin: 0 0.3rem;
	}
	.tab_cont {
		display: inline-block;
		position: relative;
		bottom: -1px;
		.tabs {
			display: flex;
			align-items: center;
			justify-content: center;
			border: 0.02rem solid $color_main;
			box-sizing: border-box;
			border-radius: 0.4rem;
			overflow: hidden;

			.line {
				width: 1px;
				background-color: $line_color;
				height: 0.4rem;
				margin: 0;
			}
			.tab_item {
				height: 0.8rem;
				font-size: 0.28rem;
				width: 3.12rem;
				text-align: center;
				line-height: 0.8rem;
			}
			.active {
				background-color: $color_main;
				color: $color_1;
			}
		}

		.line_box {
			width: 100%;
			font-size: 0;
			.line {
				display: inline-block;
				width: 50%;
				height: 1px;
				background-color: $line_color;
				margin: 0;
			}
			.active {
				background-color: $color_main;
			}
		}
	}
}
.card_box {
	.new_main_title {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-size: 0.28rem;
		font-weight: 600;
		padding-bottom: 0.2rem;
	}
	.line_title {
		width: 0.08rem;
		height: 0.32rem;
		background: $b_color_main;
		margin-right: 0.2rem;
	}
	.ml_0 {
		margin: 0.2rem 0 0.2rem;
	}
}
.padding_info{
	padding: 0.2rem;
}
.btn_radius{
	border-radius: 8px;
}

</style>

<template>
	<div :class="['assured card_box',{'padding_info':onlyUseIdcardForm}]">
		<template v-if="!onlyShowForm">
			<div class="new_main_title">
				<div class="line_title"></div>
				<span v-text="`${listTitle}(${userList.length}/200)`"></span>
			</div>

			<van-cell-group :border="false" v-show="userList.length">
				<van-cell v-for="(item, index) in userList" :key="index" title-class="cell_title" value-class="cell_value" class="user_info_cell" :border="true">
					<div class="user_info_row">
						<div class="user_title" slot="title">
							<span class="index" v-text="index + 1"></span>
							<span class="name" v-text="item.userName"></span>
							<span class="card_num" v-text="item.cardNum"></span>
							<!-- <span class="mobile" v-text="item.phone"></span> -->
						</div>
						<span class="user_icon_delete iconfont ybx_delete" slot="icon" @click.stop="deleteUser(index)"></span>
					</div>
				</van-cell>
			</van-cell-group>
		</template>

		<div class="tab_box" v-if="!onlyUseIdcardForm && proId">
			<div :class="['tab_line_box', { ml_0: proId != 27555 }]">
				<div class="tab_cont">
					<div class="tabs">
						<span class="tab_item" :class="{ active: isIdCardTab }" @click="isIdCardTab = true">&nbsp;&nbsp;{{ $t('idCardInsure') }}&nbsp;&nbsp;</span>
						<span v-if="proId != 27555" class="line"></span>
						<span v-if="proId != 27555" class="tab_item" :class="{ active: !isIdCardTab }" @click="isIdCardTab = false" v-text="$t('isNotIdCardInsure')">非身份证投保</span>
					</div>
					<!-- <div class="line_box"> -->
					<!-- <span class="line" :class="{ active: isIdCardTab && proId != 27555 }"></span> -->
					<!-- <span class="line" :class="{ active: !isIdCardTab }"></span> -->
					<!-- </div> -->
				</div>
			</div>
		</div>
		<van-form v-show="isIdCardTab" ref="form" input-align="right" error-message-align="right">
			<van-cell-group :border="false">
				<van-field :label="$t('name')" v-model="userInfo.userName" name="userName" :border="false" :formatter="nameFormatter" class="no_link" label-class="cell_title" value-class="cell_value" :rules="rules.userName" :placeholder="userList.length ? $t('moreAssured') : $t('fillName')" />
				<van-field :label="$t('idCardNo')" v-model="userInfo.cardNum" name="caerNum" :border="false" label-class="cell_title" value-class="cell_value" class="no_link" :formatter="idCardNoFormatter" @input="cardNumChange" :rules="rules.cardNum" :placeholder="$t('fillIdCard')" />
				<van-field :label="$t('mobile')" v-model="userInfo.phone" name="phone" :border="false" label-class="cell_title" :formatter="mobileFormatter" value-class="cell_value" class="no_link" @input="phoneChange" :rules="rules.phone" :placeholder="$t('notRequired')" />
				<van-field :label="$t('insuranceCode')" v-if="isShowInsuranceCode" v-model="userInfo.insuranceCode" name="insuranceCode" label-class="cell_title" value-class="cell_value" class="no_link" :rules="rules.insuranceCode" :placeholder="$t('fillInsuranceCode')" />
			</van-cell-group>
		</van-form>
		<template v-if="!onlyUseIdcardForm">
			<add-passport-user v-show="!isIdCardTab" ref="addPassportUserRef" @infoIsReady="passportInfoIsReady" :onlyShowForm="true"></add-passport-user>
		</template>

		<div class="btns" v-if="!onlyShowForm" :class="{ btns_user_pay_type: isUserPay }">
			<template v-if="isUserPay">
				<van-button plain type="info" block class="add_assured" @click="addUser(false)">{{ $t('addToTheRegistry') }}</van-button>
				<!-- <van-button plain type="info" block @click="toAddPassportUserPage">{{$t('passportToRegister')}}</van-button> -->
			</template>
			<template v-else>
				<van-button type="info" block class="add_assured btn_radius" @click="addUser(false)">{{ $t('addToAssured') }}</van-button>
				<!-- <van-button plain type="info" block @click="toAddPassportUserPage">{{$t('passportInsured')}}</van-button> -->
			</template>
		</div>
	</div>
</template>

<script>
import { Cell, CellGroup, Popup, Picker, Calendar, Field, DatetimePicker, Checkbox, Form, Button, Icon, Toast } from 'vant';
import regular from '@/assets/js/regular';
import Mtils from 'mtils';
import addPassportUser from '../addPassportUser.vue';

export default {
	name: 'assured', // 被保险人
	components: {
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[Popup.name]: Popup,
		[Picker.name]: Picker,
		[Calendar.name]: Calendar,
		[Field.name]: Field,
		[DatetimePicker.name]: DatetimePicker,
		[Checkbox.name]: Checkbox,
		[Form.name]: Form,
		[Button.name]: Button,
		[Icon.name]: Icon,
		addPassportUser,
	},
	props: {
		// 是否只使用身份证表单
		onlyUseIdcardForm: {
			type: Boolean,
			required: false,
			default: false,
		},

		// 是否只显示表单
		onlyShowForm: {
			type: Boolean,
			required: false,
			default: false,
		},

		// 是否为登记信息模式
		isUserPay: {
			type: Boolean,
			required: false,
			default: false,
		},

		// 是否显示保险编码
		isShowInsuranceCode: {
			type: Boolean,
			required: false,
			default: false,
		},

		// 产品ID 嗨玩九州境内游被保人证件类型只能是身份证
		proId: {
			type: Number | String,
			required: false,
			default: '',
		},
	},
	data() {
		return {
			rules: {
				userName: [
					{ required: true, message: '' },
					{ pattern: regular.name1, message: '只能输入中文或英文以及·' },
					{ pattern: regular.name2, message: '至少2个中文或4个英文字母' },
					{ validator: this.nameCheck, message: '·不能出现在首尾' },
				],
				cardNum: [
					{ required: true, message: '' },
					{ validator: this.cardNumCheck, message: '证件号码有误' },
				],
				phone: [{ validator: this.phoneCheck, message: '请填写正确的手机号码', trigger: 'onBlur' }],
				insuranceCode: [
					{ required: true, message: '' },
					{ pattern: /^[A-z0-9]{8}$/, message: '请输入8位保险编码' },
				],
			},

			userInfo: {
				userName: '',
				cardType: '01',
				cardNum: '',
				birthday: '',
				sex: '',
				phone: '',
				insuranceCode: '',
			},

			userList: [],

			isIdCardTab: true, // tab 当前是否为身份证
		};
	},
	computed: {
		listTitle() {
			return this.isUserPay ? this.$t('registrationList') : this.$t('assuredList');
		},
	},
	methods: {
		getResult() {
			return new Promise(resolve => {
				// 把输入框中的信息添加到被保险人列表中
				this.addUser(true).then(() => {
					if (this.userList.length) {
						resolve(this.userList);
					} else {
						Toast.fail('请添加被保险人');
					}
				});
			});
		},

		// 获取表单填写内容
		getFormValue() {
			return new Promise(resolve => {
				this.$refs.form.validate().then(() => {
					resolve(this.userInfo);
				});
			});
		},

		// rule-投保人名称检测
		nameCheck(val) {
			if (val[0] === '·' || val[val.length - 1] === '·') {
				return false;
			}
			return true;
		},

		// rule-身份证号码校验方法
		cardNumCheck(val) {
			return Mtils.validation.isIdCard(val);
		},

		phoneCheck(val) {
			if (!val) {
				return true;
			}
			return regular.phone.test(val);
		},

		// 投保人姓名格式化
		nameFormatter(v) {
			return v.toLocaleUpperCase().replace(/ /g, '');
		},

		// 身份证号码格式化
		idCardNoFormatter(v) {
			return String(v).toLocaleUpperCase().replace(/ /g, '');
		},

		// 手机号码格式化
		mobileFormatter(v) {
			return String(v).replace(/ /g, '');
		},

		// 证件号码输入-计算出生日期与性别
		cardNumChange(val) {
			if (Mtils.validation.isIdCard(val)) {
				let info = this.$base.getInfoFromIdNumber(val);
				this.userInfo.birthday = info.birthday;
				this.userInfo.sex = info.sex;

				// if (this.userInfo.userName) {
				//     this.addUser()
				// }
			}
		},

		// 手机号码
		phoneChange(val) {
			if (!this.onlyShowForm && regular.phone.test(val) && Mtils.validation.isIdCard(this.userInfo.cardNum) && this.userInfo.userName) {
				this.addUser();
			}
		},

		// 护照信息已经填写完成
		passportInfoIsReady() {
			if (!this.onlyShowForm) {
				this.addUser();
			}
		},

		// 添加被保险人-身份证
		addUser(isSubmitAdd = false) {
			return new Promise(resolve => {
				setTimeout(() => {
					if (this.isIdCardTab) {
						// 身份证
						this.$refs.form
							.validate()
							.then(() => {
								if (this.userList.length < 200) {
									if (!this.isHas(this.userInfo.cardNum)) {
										this.userList.push(this.userInfo);
										this.$set(this, 'userInfo', {
											userName: '',
											cardType: '01',
											cardNum: '',
											birthday: '',
											sex: '',
											phone: '',
										});
									}
									resolve();
								} else {
									Toast.fail('最多支持200个被保险人');
								}
							})
							.catch(err => {
								if (isSubmitAdd) {
									if (this.userInfo.userName || this.userInfo.cardNum || this.userInfo.phone) {
										Toast.fail('被保险人信息有误');
									} else {
										this.$refs.form.resetValidation();
										resolve();
									}
								}
							});
					} else {
						// 非身份证
						this.$refs.addPassportUserRef.getFormValue(isSubmitAdd).then(res => {
							if (this.userList.length < 200) {
								if (res) {
									this.addPassportUser(res);
									this.$refs.addPassportUserRef.resetForm();
								}
								resolve();
							} else {
								Toast.fail('最多支持200个被保险人');
							}
						});
					}
				}, 100);
			});
		},

		// 去添加护照被保险人页面
		toAddPassportUserPage() {
			this.$router.push({
				path: 'addPassportUser',
				query: this.$route.query,
			});
		},

		// 证件号码查重
		isHas(cardNo) {
			let hasNos = this.userList.map(item => item.cardNum);
			let result = hasNos.includes(cardNo);
			if (result) {
				Toast.fail('该证件号码已经存在');
			}
			return result;
		},

		// 添加护照被保险人
		addPassportUser(info) {
			if (!this.isHas(info.cardNum)) {
				this.userList.push(info);
			}
		},

		deleteUser(index) {
			this.userList.splice(index, 1);
		},
	},
};
</script>
