<style lang="scss" scoped>
::v-deep .van-cell {
	padding: 0.2rem 0;
	border-bottom: 0.02rem solid #eeeff0;

	&:last-child {
		border-bottom: 0;
	}
}
::v-deep .cell_title {
	color: $font_color_sec;
	font-size: 0.24rem;
}
::v-deep .cell_value {
	color: $font_color_val;
	font-size: 0.24rem;
}
::v-deep .van-field__body input {
	color: $font_color_val;
}
::v-deep .no_link .van-icon-arrow {
	color: #fff;
}
.card_box {
	box-shadow: 0rem 0.04rem 0.16rem 0rem rgba(0, 0, 0, 0.2);
	border-radius: 0.2rem 0.2rem 0.2rem 0.2rem;
	padding: 0.2rem;
	.new_main_title {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-size: 0.28rem;
		font-weight: 600;
		padding-bottom: 0.2rem;
	}
	.line_title {
		width: 0.08rem;
		height: 0.32rem;
		background: $b_color_main;
		margin-right: 0.2rem;
	}
}
</style>

<template>
	<div class="insure_info card_box">
		<div class="new_main_title">
			<div class="line_title"></div>
			<span>{{ $t('insuranceInformation') }}</span>
		</div>

		<van-form ref="form" input-align="right" error-message-align="right">
			<van-cell-group :border="false">
				<van-field :label="$t('insuredPeriod')" :disabled="fixedEnableChange === 0" :value="proteceInfo.time" label-class="cell_title" value-class="cell_value" :border="false" readonly is-link clickable name="picker" :placeholder="$t('insuredPeriod')" @click="openPop('timePop')" :rules="rules.time" />
				<van-field :label="$t('commencementDate')" :disabled="fiexdEnableDate" :value="proteceInfo.start" label-class="cell_title" value-class="cell_value" :border="false" readonly is-link clickable name="calendar" :placeholder="$t('commencementDate')" @click="openPop('startDatePop')" :rules="rules.start" />
				<van-field :label="$t('terminationDate')" :disabled="fiexdEnableDate" :value="proteceInfo.end" label-class="cell_title" value-class="cell_value" :border="false" readonly is-link clickable name="calendar" :placeholder="$t('terminationDate')" @click="openPop('endDatePop')" :rules="rules.end" />
			</van-cell-group>
		</van-form>

		<!-- 起保日期 -->
		<van-calendar v-model="startDatePop" @confirm="startDateCheck" :min-date="startDateMinDate" color="#2594EF" :formatter="$base.calendarFormatter" />
		<!-- 终保日期 -->
		<van-calendar v-model="endDatePop" @confirm="endDateCheck" :default-date="new Date(proteceInfo.end)" :min-date="endDateMinDate" :max-date="endDateMaxDate" color="#2594EF" :formatter="$base.calendarFormatter" />
		<!-- 保障期限 -->
		<van-popup v-model="timePop" position="bottom" :style="{ height: '30vh' }">
			<van-picker :title="$t('insuredPeriod')" show-toolbar value-key="view_time" :visible-item-count="3" :columns="timeList" @confirm="timeCheck" @cancel="timePop = false" />
		</van-popup>
	</div>
</template>

<script>
import { Cell, CellGroup, Popup, Picker, Calendar, Field, DatetimePicker, Checkbox, ActionSheet, Button, CountDown, Form, Toast, Dialog } from 'vant';
import { productDictInfo } from '@/request/api';
import moment from 'moment';

export default {
	name: 'insureInfo', // 投保信息
	components: {
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[Popup.name]: Popup,
		[Picker.name]: Picker,
		[Calendar.name]: Calendar,
		[Field.name]: Field,
		[DatetimePicker.name]: DatetimePicker,
		[Checkbox.name]: Checkbox,
		[ActionSheet.name]: ActionSheet,
		[Button.name]: Button,
		[CountDown.name]: CountDown,
		[Form.name]: Form,
		[Toast.name]: Toast,
		[Dialog.Component.name]: Dialog.Component,
	},
	props: {
		// 默认保障期限是否可以修改 0-不可改 1-可改
		fixedEnableChange: {
			type: Number,
			required: false,
			default: 0,
		},

		// 是否固定保障时间  true-固定  false-不固定
		fiexdEnableDate: {
			type: Boolean,
			required: false,
			default: false,
		},

		product: {
			type: Object,
			required: true,
		},
		proId: {
			type: [Number, String],
			required: false,
			default: '',
		},

		planId: {
			type: [Number, String],
			required: false,
			default: '',
		},

		priceId: {
			type: [Number, String],
			required: false,
			default: '',
		},

		// 默认开始生效时间 -1：根据产品自动选择最早生效时间；0，如果可以就当天生效；正数：N天后0点生效
		enabledDateStart: {
			type: [Number, String],
			required: false,
			default: '',
		},
	},
	data() {
		return {
			startDatePop: false,
			startDateMinDate: new Date(),
			startSecond: '00:00:00',
			isNextSecondStart: false, // 是否即时起保

			endDatePop: false,
			endDateMinDate: new Date(),
			endDateMaxDate: new Date(),

			timeLimitDict: null,
			timePop: false,
			proteceInfo: {
				time: '',
				start: '',
				end: '',
			},

			rules: {
				time: [{ required: true }],
				start: [{ required: true }],
			},
			timeList: [],
		};
	},
	watch: {
		proId: {
			handler: function (v) {
				if (v && this.planId) {
					this.getPlanInfo(v, this.planId);
				}
			},
			immediate: true,
		},

		planId: {
			handler: function (v) {
				if (v && this.proId) {
					this.getPlanInfo(this.proId, v);
				}
			},
			immediate: true,
		},

		product: {
			handler: function (v) {
				if (Object.hasOwnProperty.call(v, 'immediatelyFlag')) {
					this.setStartDate();
				}
			},
			immediate: true,
			deep: true,
		},
	},
	methods: {
		getResult() {
			return new Promise(resolve => {
				this.$refs.form
					.validate()
					.then(() => {
						let result = {
							isNextSecondStart: this.isNextSecondStart,
							startSecond: this.startSecond,
						};
						Object.assign(result, this.proteceInfo);
						resolve(result);
					})
					.catch(() => {
						Toast.fail('请检查投保信息');
					});
			});
		},

		openPop(key) {
			if (key === 'timePop') {
				if (this.fixedEnableChange === 0) {
					return false;
				}
			} else {
				if (this.fiexdEnableDate) {
					return false;
				}
			}
			this[key] = true;
		},
		startDateCheck(val) {
			if (this.product.immediatelyFlag && this.product.immediatelyTimeLimit > new Date().getHours()) {
				//即时投保
				let date = new Date(new Date().getTime() + 2 * 60 * 60 * 1000);
				this.startSecond = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':' + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':' + (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
				this.isNextSecondStart = true;
			} else {
				this.startSecond = '00:00:00';
				this.isNextSecondStart = false;
			}

			this.proteceInfo.start = this.$base.dateFormater(val);
			this.startDatePop = false;
			this.setEndDate();
		},

		setStartDate() {
			if (this.product.immediatelyFlag && this.product.immediatelyTimeLimit > new Date().getHours()) {
				//即时投保
				this.startDateMinDate = new Date();
			} else {
				this.startDateMinDate = new Date(Date.now() + 8.64e7);
			}
		},

		// 计算终保日期
		setEndDate() {
			if (this.proteceInfo.time && this.proteceInfo.start) {
				let startDate = new Date(this.proteceInfo.start);
				let timeDict = this.timeLimitDict;
				// insureTimeUnit 1-天;2-月;3-年;4-终身;5-至被保人?周岁
				// 此处为旅意险，只处理天、月、年的情况
				let momentAddUnit = 'd';
				switch (timeDict.insure_time_unit) {
					case 1:
						// 天
						momentAddUnit = 'd';
						break;

					case 2:
						// 月
						momentAddUnit = 'M';
						break;

					case 3:
						// 年
						momentAddUnit = 'y';
						break;

					default:
						break;
				}
				let endTime = moment(this.proteceInfo.start).add(Number(timeDict.insure_time), momentAddUnit).subtract(1, 'd').format('YYYY/MM/DD');
				this.proteceInfo.end = endTime;
				this.endDateMaxDate = new Date(endTime);

				if (timeDict.view_time.includes('-')) {
					let minDaysStr = timeDict.view_time.split('-')[0];
					let minEndMs = startDate.getTime() + (Number(minDaysStr) - 1) * 24 * 60 * 60 * 1000;
					this.endDateMinDate = new Date(minEndMs);
				} else {
					this.endDateMinDate = new Date(endTime);
				}
			}
		},

		endDateCheck(val) {
			this.proteceInfo.end = this.$base.dateFormater(val);
			this.endDatePop = false;
		},

		timeCheck(val) {
			this.timeLimitDict = val;
			this.proteceInfo.time = val.view_time;
			this.timePop = false;
			this.setEndDate();
			this.$emit('insureTimeChange', val);
		},

		// 获取计划信息
		getPlanInfo(proId, planId) {
			productDictInfo(proId, planId).then(res => {
				this.timeList = res.insureTimeList;
				if (res.insureTimeList.length) {
					// 设置保障期限
					if (this.priceId) {
						let priceInfoList = res.insureTimeList.filter(item => item.price_id === this.priceId);
						if (priceInfoList.length) {
							this.timeCheck(priceInfoList[0]);
						} else {
							this.timeCheck(res.insureTimeList[0]);
						}
					} else {
						this.timeCheck(res.insureTimeList[0]);
					}

					// 设置生效时间
					if (this.fiexdEnableDate) {
						if (this.enabledDateStart || this.enabledDateStart == 0) {
							if (Number(this.enabledDateStart) <= 0) {
								if (this.product.immediatelyFlag && this.product.immediatelyTimeLimit > new Date().getHours()) {
									// 即时起保
									this.startDateCheck(new Date());
								} else {
									// 非即时起保
									this.startDateCheck(new Date(Date.now() + 24 * 3600 * 1000));
								}
							} else {
								this.startDateCheck(new Date(Date.now() + 24 * 3600 * 1000 * Number(this.enabledDateStart)));
							}
						}
					}
				}
			});
		},
	},
};
</script>
